@use 'src/styles/core' as *;

.tabs {
  gap: var(--spacer-32);
  display: flex;
  flex-direction: column;

  &__menu {
    border: 1px solid $gray-50;
    display: flex;
    padding: 4px;
    margin-right: auto;
    border-radius: 100px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      border-radius: 12px;
      flex-direction: column;
    }
  }

  &__link {
    color: $gray-50;
    border: none;
    outline: none;
    padding: 12px 16px;
    display: inline-flex;
    user-select: none;
    align-items: center;
    border-radius: 100px;
    justify-content: center;
    transition-property: color, background;

    @include media-breakpoint-down(sm) {
      border-radius: 12px;
    }

    @include media-hover {
      color: $gray-70;
    }

    &_active {
      color: $gray-10;
      background: $gray-70;
      pointer-events: none;
    }
  }

  &__item {
    display: none;
    flex-direction: column;

    &_active {
      display: flex;
    }
  }

  &__item-body {
    gap: var(--spacer-32);
    display: flex;
    margin-top: var(--spacer-32);
    flex-direction: column;
  }
}
