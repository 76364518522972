@use 'colors' as *;

$root-font-size: 16 !default;

$inter-font-family: 'Inter', sans-serif !default;
$kanit-font-family: 'Kanit', sans-serif !default;

$h1-color: $gray-80 !default;
$h1-font-size: 70px !default;
$h1-font-family: $kanit-font-family !default;
$h1-font-weight: 600 !default;
$h1-line-height: 1 !default;
$h1-letter-spacing: 0.01em;

$h2-color: $gray-80 !default;
$h2-font-size: 44px !default;
$h2-font-family: $kanit-font-family !default;
$h2-font-weight: 600 !default;
$h2-line-height: 1 !default;
$h2-letter-spacing: 0.01em;

$h3-color: $gray-80 !default;
$h3-font-size: 24px !default;
$h3-font-family: $inter-font-family !default;
$h3-font-weight: 700 !default;
$h3-line-height: 1.2 !default;
$h3-letter-spacing: normal;

$h4-color: $gray-80 !default;
$h4-font-size: 20px !default;
$h4-font-family: $inter-font-family !default;
$h4-font-weight: 700 !default;
$h4-line-height: 1.3 !default;
$h4-letter-spacing: normal;

$font-sizes: (
  h1: (
    init: $h1-font-size,
    md: 56px,
    sm: 44px,
  ),
  h2: (
    init: $h2-font-size,
    md: 38px,
    sm: 32px,
  ),
  h3: (
    init: $h3-font-size,
  ),
  h4: (
    init: $h4-font-size,
  ),
) !default;
