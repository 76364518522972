@use 'src/styles/core' as *;

.footer {
  $b: &;

  border-top: 2px solid $gray-30;

  &__inner {
    gap: var(--spacer-60);
    display: flex;
    padding-block: var(--spacer-80);
    flex-direction: column;
  }

  &__logo {
    margin: 0 auto;
  }

  &__menu {
    color: $gray-80;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      gap: 40px 24px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }

  &__list {
    gap: 6px;
    display: flex;
    flex-direction: column;

    &:nth-child(2n - 1) {
      @include media-breakpoint-between(sm, md) {
        align-items: flex-end;
      }
    }
  }

  &__item {
    margin-bottom: 0;
  }

  &__link {
    gap: 4px;
    display: inline-flex;
    line-height: 24px;
    align-items: center;
    text-decoration-color: transparent;

    &:hover {
      color: $primary-color;

      #{$b}__link-name {
        text-decoration-color: currentColor;
      }
    }
  }

  &__link-name {
    text-decoration: inherit;
  }

  &__copy {
    color: $gray-80;
    margin: 0 auto;
    font-size: 12px;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }
}
