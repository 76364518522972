@use 'src/styles/core' as *;

.posts-menu {
  $b: &;

  gap: 8px;
  display: flex;
  align-items: flex-start;

  &__label {
    color: $gray-80;
    height: 28px;
    display: flex;
    align-items: center;
  }

  &__body {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    color: $gray-80;
    display: flex;
    padding: 8px 12px;
    font-size: 12px;
    background: $gray-10;
    line-height: 1;
    align-items: center;
    border-radius: 6px;
    outline-offset: 2px;
    text-decoration: none;
    transition-property: color, outline, background;

    &_active {
      color: $gray-10;
      background: $gray-80;
    }
  }
}
