@use 'src/styles/core' as *;

@function icon($name) {
  @return url('/src/uploads/next/assets/icons/' + $name + '.svg');
}

.icon {
  display: inline-flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;

  &::after {
    width: 1em;
    height: 1em;
    content: '';
    display: flex;
    background-color: currentColor;
    transition-property: transform;
    mask: {
      size: contain;
      repeat: no-repeat;
      position: center;
    }
  }

  &_link::after {
    mask-image: icon('link');
  }

  &_vimeo::after {
    mask-image: icon('vimeo');
  }

  &_twitter::after {
    mask-image: icon('twitter');
  }

  &_facebook::after {
    mask-image: icon('facebook');
  }

  &_linkedin::after {
    mask-image: icon('linkedin');
  }

  &_instagram::after {
    mask-image: icon('instagram');
  }

  &_play::after {
    mask-image: icon('play');
  }

  &_plus::after {
    mask-image: icon('plus');
  }

  &_minus::after {
    mask-image: icon('minus');
  }

  &_close::after {
    mask-image: icon('close');
  }

  &_arrow-left::after {
    transform: rotate(180deg);
    mask-image: icon('arrow-right');
  }

  &_arrow-right::after {
    mask-image: icon('arrow-right');
  }

  &_chevron-down::after {
    mask-image: icon('chevron-down');
  }

  &_arrow-up-right::after {
    mask-image: icon('arrow-up-right');
  }
}
