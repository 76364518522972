@use 'colors' as *;
@use 'typography' as *;

$body-bg: $gray-20 !default;
$text-color: $gray-70 !default;

// base-font
$base-font-size: $root-font-size + px !default;
$base-font-weight: 400 !default;
$base-font-family: $inter-font-family !default;
$base-line-height: 1.4 !default;

// border-radius
$border-radius-xl: 32px !default;
$border-radius-lg: 20px !default;
$border-radius-md: 12px !default;
$border-radius-sm: 8px !default;
$border-radius-xs: 4px !default;
