@use 'src/styles/core' as *;

:root {
  --gradient-red: linear-gradient(135deg, #ce7a3c 0%, #d64f67 100%);
  --gradient-pink: linear-gradient(140deg, #d64f67 0%, #dc55ae 100%);
  --gradient-blue: linear-gradient(140deg, #8b78ff 0%, #555bdc 100%);
  --gradient-green: linear-gradient(136deg, #22b678 0%, #839f10 100%);
  --gradient-yellow: linear-gradient(140deg, #d9bc23 0%, #ce793b 100%);
  --gradient-pink-blue: linear-gradient(318deg, #8b78ff 0%, #dc55ae 100%);
  --gradient-blue-green: linear-gradient(316deg, #21bd56 0%, #555bdc 100%);
  --gradient-green-yellow: linear-gradient(137deg, #839f10 0%, #d9bc23 100%);

  @include each-2level-map($spacers-map) using($key, $value, $breakpoint) {
    @include media-breakpoint-down($breakpoint) {
      --spacer-#{$key}: #{$value};
    }
  }

  @include each-2level-map($font-sizes) using($key, $value, $breakpoint) {
    @include media-breakpoint-down($breakpoint) {
      --#{$key}-font-size: #{$value};
    }
  }
}

:disabled {
  pointer-events: none;
}

*,
::after,
::before {
  background-repeat: no-repeat;
}

:where(
  a,
  input,
  button,
  textarea
) {
  outline: {
    color: transparent;
    width: 1px;
    style: solid;
    offset: 3px;
  };
}

::after,
::before,
::placeholder,
:where([class]) {
  transition: {
    property: outline;
    duration: $duration;
    timing-function: $ease;
  }
}

:focus-visible {
  &:where(:not(:hover, :active)) {
    outline-color: $gray-50;
  }
}

html {
  height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  display: flex;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  $b: &;

  flex: 1 0 auto;
  color: $text-color;
  display: flex;
  max-width: 100%;
  min-height: 100vh;
  line-height: $base-line-height;
  flex-direction: column;

  font: {
    size: $base-font-size;
    weight: $base-font-weight;
    family: $base-font-family;
  }

  background: {
    color: $body-bg;
  };

  &__main {
    gap: var(--spacer-160);
    flex: 1 0 auto;
    display: flex;
    padding-bottom: var(--spacer-160);
    flex-direction: column;

    &:nth-child(2) {
      margin-top: 60px;

      @include media-breakpoint-down(md) {
        margin-top: 40px;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }

    &:nth-child(3) {
      margin-top: 38px;
    }
  }

  &__footer {
    overflow: hidden;
  }
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

.primary {
  color: $primary-color;
}

.uppercase {
  text-transform: uppercase;
}

.no-scrollbar {
  overflow: hidden;
}

.gradient-red {
  background: var(
      --gradient-red,
      linear-gradient(135deg, #ce7a3c 0%, #d64f67 99.69%)
  );
}

.gradient-pink {
  background: var(
      --gradient-pink,
      linear-gradient(140deg, #d64f67 0%, #dc55ae 100%)
  );
}

.gradient-blue {
  background: var(
      --gradient-blue,
      linear-gradient(140deg, #8b78ff 0%, #555bdc 100%)
  );
}

.gradient-green {
  background: var(
      --gradient-green,
      linear-gradient(136deg, #22b678 0%, #839f10 100%)
  );
}

.gradient-yellow {
  background: var(
      --gradient-yellow,
      linear-gradient(140deg, #d9bc23 0%, #ce793b 100%)
  );
}

.gradient-pink-blue {
  background: var(
      --gradient-pink-blue,
      linear-gradient(318deg, #8b78ff 0%, #dc55ae 100%)
  );
}

.gradient-blue-green {
  background: var(
      --gradient-blue-green,
      linear-gradient(316deg, #21bd56 0%, #555bdc 100%)
  );
}

.gradient-green-yellow {
  background: var(
      --gradient-green-yellow,
      linear-gradient(137deg, #839f10 0%, #d9bc23 100%)
  );
}

