@use 'src/styles/core' as *;
@use 'posts-menu';
@use 'post';

.posts {
  $b: &;

  gap: var(--spacer-24);
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc-column(4);

    @include media-breakpoint-between(sm, md) {
      width: calc-column(6);
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &_blog {
    #{$b}__item {
      &:nth-child(1),
      &:nth-child(4n + 1) {
        @include media-breakpoint-up(md) {
          width: 100%;
        }
      }
    }
  }

  &_recently {
    #{$b}__item {
      &_more {
        display: none;
      }
    }
  }

  &_solutions {
    #{$b}__item {
      &:nth-child(4n) {
        @include media-breakpoint-up(md) {
          width: 100%;
        }
      }
    }
  }

  &_center {
    justify-content: center;
  }
}
