@use 'src/styles/core' as *;
@use 'feature';

.features {
  $b: &;

  gap: var(--spacer-24);
  display: grid;

  &_2col {
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }

  &_3col {
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-between(sm, md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
}
