@use 'src/styles/core' as *;

.feedback {
  --text-color: #{$gray-10};
  --headings-color: #{$gray-10};

  gap: 32px;
  display: flex;
  padding: 80px;
  background: $blue-70;
  border-radius: $border-radius-lg;
  flex-direction: column;

  @include media-breakpoint-between(sm, md) {
    padding: 60px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 40px map-get($gutter, sm);
    max-width: none;
    border-radius: 0;
  }

  &_row {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }

  &_body {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }

  &_foot {
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      gap: 40px;
      flex-direction: column-reverse;
    }
  }

  &_policy {
    font-size: 12px;
  }
}
