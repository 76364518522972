@use 'src/styles/core' as *;

.not-found {
  gap: 24px;
  display: flex;

  @include media-breakpoint-up(md) {
    align-items: center;
  }
  @include media-breakpoint-down(md) {
    gap: 60px;
    flex-direction: column;
  }

  &__cell {
    flex: 1;

    &:last-child {
      display: flex;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }
    }
  }

  &__buttons {
    gap: 40px;
    display: flex;
    margin-top: 32px;
    align-items: center;

    @include media-breakpoint-down(sm) {
      gap: 24px;
      margin-inline: auto;
      flex-direction: column;
    }
  }
}
