@use 'src/styles/core' as *;

.stack {
  $b: &;

  gap: 20px;
  display: flex;
  flex-direction: column;

  &_md {
    gap: 32px;
  }
}

.hstack {
  $b: &;

  gap: 20px;
  display: flex;
  align-items: center;

  &_md {
    gap: 32px;
  }
}
