@use 'src/styles/core' as *;

.tingle-modal {
  --headings-color: currentColor;
  --headings-margin-bottom: 12px;

  color: $gray-10;
  inset: 0;
  opacity: 0;
  z-index: 2000;
  display: flex;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  background: rgba(#000, 0.8);
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(4px);
  -webkit-overflow-scrolling: touch;

  &--visible {
    opacity: 1;
    visibility: visible;

    .tingle-modal-box {
      animation: scale .2s cubic-bezier(.68, -.55, .265, 1.55) forwards;
    }
  }

  &--overflow {
    overflow-y: scroll;
  }
}

.tingle-enabled {
  left: 0;
  right: 0;
  position: fixed;
  overflow: hidden;
}

.tingle-modal-box {
  width: 100%;
  opacity: 1;
  display: flex;
  padding: 80px 104px 60px;
  position: relative;
  max-width: 856px;
  background: $blue-70;
  flex-shrink: 0;
  will-change: opacity, transform;
  margin-block: auto;
  border-radius: $border-radius-lg;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    max-width: 856px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    border-radius: 0;
    padding-inline: 16px;
  }

  &__content {
    @include media-breakpoint-down(sm) {
      margin-top: auto;
    }
  }

  &__footer {
    display: flex;
  }

  .tingle-modal-close {
    top: 16px;
    right: 16px;
    z-index: 10;
    position: absolute;
    background: $blue-60;
  }

  .tingle-modal-finish {
    margin-top: 32px;
    margin-left: auto;
  }
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
