@use 'src/styles/core' as *;

.header {
  $b: &;

  top: 0;
  z-index: 100;
  display: flex;
  position: sticky;
  background: rgba($gray-20, 0.5);
  margin-top: 12px;
  user-select: none;
  flex-direction: column;
  backdrop-filter: blur(8px);

  &__main {
    display: flex;
    align-items: center;
    margin-block: 20px;
    justify-content: space-between;
  }

  &__logo {
    z-index: 15;
    position: relative;
  }

  &__switch {
    gap: 6px;
    z-index: 15;
    display: none;
    position: relative;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      display: flex;
    }
  }

  &__switch-line {
    width: 20px;
    height: 2px;
    position: relative;
    background: currentColor;
    border-radius: 2px;
    transition-property: all;
  }

  &_menu-open {
    z-index: 1500;

    #{$b}__switch-line {
      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: scaleX(0.6);
        transition-delay: -0.15s;
      }

      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
}
