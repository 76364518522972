@use 'sass:math' as *;

@use 'src/styles/core' as *;

.columns {
  column-gap: 24px;

  &_2col {
    column-count: 2;

    @include media-breakpoint-down(md) {
      column-count: 1;
    }
  }
}
