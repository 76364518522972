@use 'src/styles/core' as *;

.links {
  $b: &;

  gap: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }

  &__item {
    gap: 80px;
    color: $gray-10;
    display: flex;
    padding: 24px;
    box-shadow: 0 0 0 $primary-color;
    border-radius: $border-radius-lg;
    flex-direction: column;
    text-decoration: none;
    background-color: $blue-80;
    transition-property: outline, box-shadow;

    @include media-breakpoint-down(md) {
      gap: 32px;
    }

    &:nth-child(1) {
      background-image: linear-gradient(
          0deg,
          rgba(57, 57, 72, 0.80) 0%,
          rgba(57, 57, 72, 0.80) 100%
      );
    }

    &:nth-child(2) {
      background-image: linear-gradient(
          0deg,
          rgba(57, 57, 72, 0.40) 0%,
          rgba(57, 57, 72, 0.40) 100%
      );
    }

    @include media-hover {
      background: $primary-color !important;
      box-shadow: 0 0 0 4px $primary-color;

      #{$b}__icon {
        transform: translate(5px, -5px);
      }
    }
  }

  &__icon {
    margin-left: auto;
    transition-property: transform;
  }

  &__name {
    color: $gray-10;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
  }
}
