@use 'sass:map';
@use 'sass:list';
@use 'sass:meta';

@use '../variables' as v;

@mixin make-icon($name) {
  @if (map-get(v.$icons, $name) != null) {
    width: 1em;
    height: 1em;
    display: inline-flex;
    font-size: 24px;
    background-color: currentColor;
    mask: {
      size: contain;
      image: url('/src/uploads/next/assets/icons/' + $name + '.svg');
      repeat: no-repeat;
      position: center;
    }
  }
}

@mixin media-hover() {
  @media (hover) {
    &:hover {
      @content;
    }
  }
}

@mixin focus-visible() {
  &:focus-visible {
    &:where(:not(:hover, :active)) {
      @content;
    }
  }
}

@mixin media-breakpoint-up($value) {
  $minWidth: $value;

  @if (meta.type-of($value) == string) {
    $minWidth: map.get(v.$breakpoints, $value);
  }

  @if ($minWidth != null) {
    @media (min-width: $minWidth) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($value) {
  $maxWidth: $value;

  @if (meta.type-of($value) == string) {
    $maxWidth: map.get(v.$breakpoints, $value);
  }

  @if ($maxWidth != null) {
    @media (max-width: $maxWidth - 0.2) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper) {
  $minWidth: $lower;
  $maxWidth: $upper;

  @if (meta.type-of($lower) == string) {
    $minWidth: map.get(v.$breakpoints, $lower);
  }

  @if (meta.type-of($upper) == string) {
    $maxWidth: map.get(v.$breakpoints, $upper);
  }

  @if ($minWidth != null and $maxWidth != null) {
    @media (min-width: $minWidth) and (max-width: $maxWidth - 0.2) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin each-1level-map($map) {
  @each $key, $value in $map {
    @content ($key, $value);
  }
}

@mixin each-2level-map($map) {
  @each $key, $props in $map {
    @each $breakpoint, $value in $props {
      @content ($key, $value, $breakpoint);
    }
  }
}

@mixin visually-hidden() {
  clip: rect(0, 0, 0, 0) !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;

  &:not(caption) {
    position: absolute !important;
  }
}
