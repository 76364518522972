@use 'src/styles/core' as *;

.feature {
  $b: &;

  gap: 24px;
  display: flex;
  padding: 24px;
  background: $gray-10;
  border-radius: 20px;
  flex-direction: column;

  &__ico {
    border-radius: 16px;
  }

  &__img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 344 / 192;
    border-radius: 16px;
  }

  &_primary {
    background: $blue-80;
  }
}
