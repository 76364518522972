@use 'src/styles/core' as *;

// dark
$btn-dark-bg: $gray-70 !default;
$btn-dark-color: $gray-10 !default;

$btn-dark-focus-bg: $gray-70 !default;
$btn-dark-focus-color: $gray-10 !default;

$btn-dark-hover-bg: $gray-60 !default;
$btn-dark-hover-color: $gray-10 !default;

$btn-dark-active-bg: $gray-70 !default;
$btn-dark-active-color: $gray-10 !default;

$btn-dark-disabled-bg: $gray-70 !default;
$btn-dark-disabled-color: $gray-10 !default;

// default
$btn-default-bg: $gray-10 !default;
$btn-default-color: $gray-80 !default;

$btn-default-focus-bg: $gray-10 !default;
$btn-default-focus-color: $gray-80 !default;

$btn-default-hover-bg: $gray-30 !default;
$btn-default-hover-color: $gray-80 !default;

$btn-default-active-bg: $blue-30 !default;
$btn-default-active-color: $gray-80 !default;

$btn-default-disabled-bg: $gray-10 !default;
$btn-default-disabled-color: $gray-80 !default;

// primary
$btn-primary-bg: $primary-color !default;
$btn-primary-color: $gray-10 !default;

$btn-primary-focus-bg: $primary-color !default;
$btn-primary-focus-color: $gray-10 !default;

$btn-primary-hover-bg: $primary-color-hover !default;
$btn-primary-hover-color: $gray-10 !default;

$btn-primary-active-bg: $primary-color-active !default;
$btn-primary-active-color: $gray-10 !default;

$btn-primary-disabled-bg: $primary-color !default;
$btn-primary-disabled-color: $gray-10 !default;

.btn {
  $b: &;

  border: 1px solid transparent;
  display: inline-flex;
  position: relative;
  user-select: none;
  align-items: center;
  border-radius: 12px;
  text-decoration: none;
  justify-content: center;
  transition-property: color, border, outline, background;

  &__spinner {
    inset: 0;
    z-index: 1;
    opacity: 0;
    position: absolute;
    background: inherit;
    border-radius: inherit;
    pointer-events: none;
    transition-property: opacity;

    &::after {
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin: -10px 0 0 -10px;
      border: 2px solid currentColor;
      content: '';
      position: absolute;
      animation: spin 1s linear infinite both;
      border-radius: 50%;
      border-right-color: transparent;
    }
  }

  &:disabled {
    opacity: 0.36;
  }

  // type
  &_base {
    gap: 8px;
    font-weight: 600;

    &-md {
      padding: 11px 19px;
      min-width: 100px;
      font-size: 16px;
      line-height: 1.5;
    }

    &-lg {
      padding: 21px 27px;
      font-size: 20px;
      min-width: 180px;
      line-height: 1.2;
    }
  }

  &_icon {
    font-size: 24px;
    line-height: 1;

    &::after {
      transition-property: transform;
    }

    &-md {
      padding: 11px;
      min-width: 48px;
      min-height: 48px;
    }

    &-lg {
      padding: 21px;
      min-width: 68px;
      min-height: 68px;
    }
  }

  // theme
  &_dark {
    color: $btn-dark-color;
    background: $btn-dark-bg;

    &:focus-visible:where(:not(:hover, :active)) {
      color: $btn-dark-focus-color;
      background: $btn-dark-focus-bg;
    }

    @include media-hover {
      color: $btn-dark-hover-color;
      background: $btn-dark-hover-bg;

      &:active {
        color: $btn-dark-active-color;
        background: $btn-dark-active-bg;
      }
    }

    &:disabled {
      color: $btn-dark-disabled-color;
      background: $btn-dark-disabled-bg;
    }
  }

  &_default {
    color: $btn-default-color;
    background: $btn-default-bg;

    &:focus-visible:where(:not(:hover, :active)) {
      color: $btn-default-focus-color;
      background: $btn-default-focus-bg;
    }

    @include media-hover {
      color: $btn-default-hover-color;
      background: $btn-default-hover-bg;

      &:active {
        color: $btn-default-active-color;
        background: $btn-default-active-bg;
      }
    }

    &:disabled {
      color: $btn-default-disabled-color;
      background: $btn-default-disabled-bg;
    }
  }

  &_primary {
    color: $btn-primary-color;
    background: $btn-primary-bg;

    &:focus-visible:where(:not(:hover, :active)) {
      color: $btn-primary-focus-color;
      background: $btn-primary-focus-bg;
    }

    @include media-hover {
      color: $btn-primary-hover-color;
      background: $btn-primary-hover-bg;

      &:active {
        color: $btn-primary-active-color;
        background: $btn-primary-active-bg;
      }
    }

    &:disabled {
      color: $btn-primary-disabled-color;
      background: $btn-primary-disabled-bg;
    }
  }

  // shape
  &_round {
    border-radius: 1000px;
  }

  &_circle {
    border-radius: 50%;
  }

  // misc
  &_block {
    display: flex;
  }

  &_center {
    margin-inline: auto;
  }

  &_loading {
    pointer-events: none;

    #{$b}__spinner {
      opacity: 1;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
