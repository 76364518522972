@use 'src/styles/core' as *;

$input-bg: $blue-80 !default;
$input-color: $gray-20 !default;
$input-radius: $border-radius-md !default;

$input-label-color: $gray-10 !default;
$input-error-color: $error-color !default;
$input-border-color: $blue-40 !default;
$input-placeholder-color: $blue-40 !default;

$input-hover-border-color: $blue-10 !default;

.input {
  $b: &;

  gap: 8px;
  display: flex;
  position: relative;
  transform: translateZ(0);
  flex-direction: column;

  &__field {
    $e: &;

    order: 2;
    width: 100%;
    color: $input-color;
    resize: none;
    border: 1px solid $input-border-color;
    outline: none;
    padding: 20px 16px;
    background: $input-bg;
    border-radius: $border-radius-md;
    transition-property: color, border, background, box-shadow;

    &::placeholder {
      color: $input-placeholder-color;
      user-select: none;
      pointer-events: none;
      transition-property: color;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }

    &:focus,
    &:hover {
      box-shadow: 0 0 0 1px $input-hover-border-color;
      border-color: $input-hover-border-color;
    }

    &:disabled {
      opacity: 0.36;
    }

    &_textarea {
      height: 152px;
    }

    &.invalid {
      box-shadow: 0 0 0 1px $input-error-color;
      border-color: $input-error-color;
    }
  }

  &__label {
    order: 1;
    color: $input-label-color;
    cursor: pointer;
    transition-property: color;
  }

  &__error {
    order: 3;
    color: $input-error-color;
    line-height: 1.2;

    &:empty {
      display: none;
    }
  }
}
