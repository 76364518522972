@use 'src/styles/core' as *;

.benefits {
  $b: &;

  gap: 24px;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }

  &__item {
    --headings-color: currentColor;

    flex: 1;
    color: $gray-10;
    padding: 32px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;

    &:nth-child(1) {
      background: linear-gradient(135deg, #CE7A3C 0%, #D64F67 99.69%);
    }
    &:nth-child(2) {
      background: linear-gradient(140deg, #D64F67 0%, #DC55AE 100%);
    }
    &:nth-child(3) {
      background: linear-gradient(318deg, #8B78FF 0%, #DC55AE 100%);
    }
    &:nth-child(4) {
      background: linear-gradient(140deg, #8B78FF 0%, #555BDC 100%);
    }
  }

  &_text-center {
    text-align: center;
  }

  &_text-middle {
    #{$b}__item {
      justify-content: center;
    }
  }
}
