@use 'src/styles/core' as *;

.expert-header {
  $b: &;

  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 2fr;

  @include media-breakpoint-down(md) {
    gap: 0;
    grid-template-columns: 1fr;
  }

  &__view {
    display: flex;
    position: relative;
    background: $gray-30;
    border-radius: 20px;

    @include media-breakpoint-down(md) {
      aspect-ratio: 684 / 490;
      border-radius: 20px 20px 0 0;
    }
  }

  &__thumb {
    flex-grow: 1;
    border-radius: inherit;

    &-src {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 10%;
    }
  }

  &__link {
    color: $gray-10;
    right: 20px;
    bottom: 20px;
    display: flex;
    padding: 12px;
    position: absolute;
    background: $blue-70;
    align-items: center;
    border-radius: 50%;
    text-decoration: none;
    justify-content: center;
  }

  &__body {
    --headings-color: $gray-10;

    color: $gray-10;
    display: flex;
    padding: 48px;
    background: $blue-70;
    border-radius: 20px;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      padding: 24px;
      border-radius: 0 0 20px 20px;
    }
  }

  &__role {
    font-size: 12px;
    line-height: 1;
  }

  &__name {
    margin-block: 8px 32px;
  }

  &__text {
  }
}
