@use 'sass:math';

@use 'src/styles/core' as *;

.media-block {
  $b: &;

  display: flex;
  align-items: center;

  &__main,
  &__side {
    flex: 1 0 50%;

    @include media-breakpoint-down(md) {
      flex: auto;
    }
  }

  &__main {
    padding: 80px;
    background: $gray-10;
    min-height: 60%;
    align-items: center;
    border-radius: 20px;

    @include media-breakpoint-between(lg, xl) {
      padding: 60px;
    }
    @include media-breakpoint-between(md, lg) {
      padding: 48px;
    }
    @include media-breakpoint-between(sm, md) {
      padding: 40px;
      min-height: auto;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px;
      min-height: auto;
    }
  }

  &__side {
    width: auto;
    display: flex;
    overflow: hidden;
    flex-shrink: 0;
    aspect-ratio: 600 / 374;
    border-radius: 20px;
    margin-block: calc(25% * #{math.sin(5deg)});

    @include media-breakpoint-down(md) {
      width: 96%;
      margin-inline: auto;
    }
    @include media-breakpoint-down(sm) {
      margin-block: calc(50% * #{math.sin(5deg)});
    }
  }

  &__object {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    border-radius: inherit;
  }

  &_ltr {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    #{$b}__main {
      padding-right: 120px;

      @include media-breakpoint-between(md, lg) {
        padding-right: 80px;
      }
      @include media-breakpoint-between(sm, md) {
        padding-right: 40px;
      }
      @include media-breakpoint-down(sm) {
        padding-right: 20px;
      }
    }

    #{$b}__side {
      transform: rotate(-5deg);
      margin-left: -60px;

      @include media-breakpoint-between(md, lg) {
        margin-left: -40px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }

  &_rtl {
    flex-direction: row-reverse;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }

    #{$b}__main {
      padding-left: 120px;

      @include media-breakpoint-between(md, lg) {
        padding-left: 80px;
      }
      @include media-breakpoint-between(sm, md) {
        padding-left: 40px;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 20px;
      }
    }

    #{$b}__side {
      transform: rotate(5deg);
      margin-right: -60px;

      @include media-breakpoint-between(md, lg) {
        margin-right: -40px;
      }
      @include media-breakpoint-down(md) {
        margin-right: auto;
        margin-bottom: 0;
      }
    }
  }
}

.media-section {
  gap: 48px;
  display: flex;
  flex-direction: column;

  &__body {
    gap: 48px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      gap: 60px;
    }
  }
}
