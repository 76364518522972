@use '../variables' as *;

.wow {
  will-change: opacity, transform;
  transition: {
    delay: calc(var(--index) * 0.025s) !important;
    duration: 0.6s !important;
    property: opacity, transform !important;
    timing-function: cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  }

  &_fade-up {
    opacity: 0 !important;
    transform: translateY(48px) !important;
  }

  &_scale-in {
    opacity: 0 !important;
    transform: scale(0.86) !important;
    transform-origin: 50% 0 !important;
  }

  &[data-scroll='in'] {
    opacity: 1 !important;
    transform: none !important;
  }
}

