@use 'src/styles/core' as *;

.section {
  gap: var(--spacer-32);
  display: flex;
  flex-direction: column;

  &__head {
    --h2-margin-top: 60px;

    :first-child {
      margin-top: 0;
    }
  }

  &__body {
    gap: var(--spacer-80);
    display: flex;
    flex-direction: column;
  }

  &__foot {
    display: flex;
    justify-content: center;
  }

  &_solid {
    --headings-color: $gray-10;

    color: $gray-10;
    padding: 100px 102px;
    background: $blue-70;
    border-radius: 20px;

    @include media-breakpoint-between(sm, md) {
      padding: 60px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 40px map-get($gutter, sm);
      max-width: none;
      border-radius: 0;
    }
  }

  &_solid-gray {
    background: $gray-80;
  }
}
