@use 'src/styles/core' as *;

.logos {
  $b: &;

  display: flex;
  position: relative;
  user-select: none;
  justify-content: center;

  &__show {
    margin: 0;
    max-width: 880px;
  }

  &__slide {
    display: flex;
  }

  &__item {
    margin: 0 auto;
    opacity: 0.5;
    display: flex;
    outline: none !important;
    align-items: center;
    border-radius: $border-radius-sm;
    transition-property: opacity, outline;

    @include media-hover {
      opacity: 1;
    }
  }

  &__arrow {
    color: $gray-80;
    opacity: 0.5;
    outline: none;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    transition-property: opacity;

    @include media-breakpoint-down(sm) {
      width: 48px;
    }

    &:hover {
      opacity: 1;
    }

    &_prev {
      padding-right: 48px;
      justify-content: flex-end;

      @include media-breakpoint-down(sm) {
        padding-right: 24px;
      }

      @include media-hover {
        &::after {
          transform: translateX(-4px) rotate(180deg);
        }
      }
    }

    &_next {
      padding-left: 48px;

      @include media-breakpoint-down(sm) {
        padding-left: 24px;
      }

      @include media-hover {
        &::after {
          transform: translateX(4px);
        }
      }
    }
  }
}
