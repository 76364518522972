@use 'src/styles/core' as *;

:where(p) {
  color: var(--text-color, currentColor);
}

:where(
  p + p,
  p + ul,
  p + ol
) {
  margin-top: 0.5rem;
}

:where(a) {
  color: var(--link-color, var(--text-color, currentColor));
  text-underline-offset: em(3);
  transition: {
    duration: $duration;
    property: color, outline, text-decoration;
    timing-function: $ease;
  }
  text-decoration: {
    line: underline;
    color: currentColor;
    thickness: 1px;
  }

  @include media-hover {
    text-underline-offset: em(2);
    text-decoration-thickness: 2px;
  }
}

:where(a:not([class])) {
  border-radius: $border-radius-xs;
}

:where(.ul, ul:not([class])) {
  padding-left: 1em;
  list-style-type: disc;
  list-style-position: inside;

  :where(
    ul, .ul,
    li + li
  ) {
    margin-top: 0.5rem;
  }
}

:where(h1, .h1) {
  color: var(--h1-color, var(--headings-color, #{$h1-color}));
  font-size: var(--h1-font-size);
  font-family: $h1-font-family;
  font-weight: $h1-font-weight;
  line-height: $h1-line-height;
  letter-spacing: $h1-letter-spacing;

  margin: {
    top: var(--h1-margin-top, 1rem);
    bottom: var(--h1-margin-bottom, var(--headings-margin-bottom, 32px));
  }
}

:where(h2, .h2) {
  color: var(--h2-color, var(--headings-color, #{$h2-color}));
  font-size: var(--h2-font-size);
  font-family: $h2-font-family;
  font-weight: $h2-font-weight;
  line-height: $h2-line-height;
  letter-spacing: $h2-letter-spacing;

  margin: {
    top: var(--h2-margin-top, 1rem);
    bottom: var(--h2-margin-bottom, var(--headings-margin-bottom, 32px));
  }
}

:where(h3, .h3) {
  color: var(--h3-color, var(--headings-color, #{$h3-color}));
  font-size: var(--h3-font-size);
  font-family: $h3-font-family;
  font-weight: $h3-font-weight;
  line-height: $h3-line-height;
  letter-spacing: $h3-letter-spacing;

  margin: {
    top: var(--h3-margin-top, 1rem);
    bottom: var(--h3-margin-bottom, var(--headings-margin-bottom, 4px));
  }
}

:where(h4, .h4) {
  color: var(--h4-color, var(--headings-color, #{$h4-color}));
  font-size: var(--h4-font-size);
  font-family: $h4-font-family;
  font-weight: $h4-font-weight;
  line-height: $h4-line-height;
  letter-spacing: $h4-letter-spacing;

  margin: {
    top: var(--h4-margin-top, 1rem);
    bottom: var(--h4-margin-bottom, var(--headings-margin-bottom, 4px));
  }
}

:where(
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4
) {
  display: block;

  mark {
    color: $primary-color;
    background: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

:where(picture img) {
  aspect-ratio: inherit;
  border-radius: inherit;
}

.table {
  overflow: auto;
  max-width: 100%;
  font-size: 14px;
  margin-block: 0.5rem;

  :where(th, td) {
    padding: 0.5rem;
    text-align: left;
    border-top: 1px solid $gray-40;
    border-left: 1px solid $gray-40;
    vertical-align: top;

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      border-left: 0;
      padding-left: 0;
    }
  }

  :where(tr:first-child) {
    :where(th, td) {
      border-top: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

