@use 'src/styles/core' as *;

.swiper {
  z-index: 1;
  padding: 0;
  position: relative;
  overflow: hidden;
  list-style: none;
}
.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  box-sizing: content-box;
  will-change: transform;
  transition-property: transform;
  transition-timing-function: ease-out;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-pointer-events {
  touch-action: pan-y;
  &.swiper-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-pagination {
  gap: 8px;
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: flex;
  position: relative;
  background: $gray-30;
  border-radius: 50%;
  transition-property: all;

  &::after {
    inset: -4px;
    content: '';
    position: absolute;
  }
}

.swiper-pagination-bullet-active {
  background: $gray-80;
}
