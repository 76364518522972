/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: currentColor; /* 2 */
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
5. Use the user's configured `sans` font-feature-settings by default.
6. Use the user's configured `sans` font-variation-settings by default.
*/
:where(html) {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
  font-feature-settings: normal; /* 5 */
  font-variation-settings: normal; /* 6 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/
:where(body) {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/
:where(hr) {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/
:where(abbr[title]) {
  text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/
:where(h1,
h2,
h3,
h4,
h5,
h6) {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/
:where(a) {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/
:where(b,
strong) {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/
:where(kbd,
pre,
code,
samp) {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/*
Add the correct font size in all browsers.
*/
:where(small) {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/
:where(sub,
sup) {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

:where(sub) {
  bottom: -0.25em;
}

:where(sup) {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
:where(table) {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/
:where(input,
button,
select,
textarea,
optgroup) {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/
:where(button,
select) {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/
:where(button,
[type=reset],
[type=button],
[type=submit]) {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/
:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/
:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/
:where(progress) {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/
:where([type=search]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/
:where(summary) {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/
:where(p,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
pre,
figure,
blockquote) {
  margin: 0;
}

:where(fieldset) {
  margin: 0;
  padding: 0;
}

:where(legend) {
  padding: 0;
}

:where(ol,
ul,
menu) {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
Prevent resizing textareas horizontally by default.
*/
:where(textarea) {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/
:where(input::placeholder,
textarea::placeholder) {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}

/*
Set the default cursor for buttons.
*/
:where(button,
[role=button]) {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/
:where(img,
svg,
video,
audio,
embed,
object,
iframe,
canvas) {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/
:where(img,
video) {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */
[hidden] {
  display: none;
}

:root {
  --gradient-red: linear-gradient(135deg, #ce7a3c 0%, #d64f67 100%);
  --gradient-pink: linear-gradient(140deg, #d64f67 0%, #dc55ae 100%);
  --gradient-blue: linear-gradient(140deg, #8b78ff 0%, #555bdc 100%);
  --gradient-green: linear-gradient(136deg, #22b678 0%, #839f10 100%);
  --gradient-yellow: linear-gradient(140deg, #d9bc23 0%, #ce793b 100%);
  --gradient-pink-blue: linear-gradient(318deg, #8b78ff 0%, #dc55ae 100%);
  --gradient-blue-green: linear-gradient(316deg, #21bd56 0%, #555bdc 100%);
  --gradient-green-yellow: linear-gradient(137deg, #839f10 0%, #d9bc23 100%);
  --spacer-160: 160px;
  --spacer-80: 80px;
  --spacer-60: 60px;
  --spacer-40: 40px;
  --spacer-32: 32px;
  --spacer-24: 24px;
  --spacer-12: 12px;
  --h1-font-size: 70px;
  --h2-font-size: 44px;
  --h3-font-size: 24px;
  --h4-font-size: 20px;
}
@media (max-width: 767.8px) {
  :root {
    --spacer-160: 120px;
  }
}
@media (max-width: 1023.8px) {
  :root {
    --spacer-80: 60px;
  }
}
@media (max-width: 767.8px) {
  :root {
    --spacer-80: 40px;
  }
}
@media (max-width: 1023.8px) {
  :root {
    --h1-font-size: 56px;
  }
}
@media (max-width: 767.8px) {
  :root {
    --h1-font-size: 44px;
  }
}
@media (max-width: 1023.8px) {
  :root {
    --h2-font-size: 38px;
  }
}
@media (max-width: 767.8px) {
  :root {
    --h2-font-size: 32px;
  }
}

:disabled {
  pointer-events: none;
}

*,
::after,
::before {
  background-repeat: no-repeat;
}

:where(a,
input,
button,
textarea) {
  outline-color: transparent;
  outline-width: 1px;
  outline-style: solid;
  outline-offset: 3px;
}

::after,
::before,
::placeholder,
:where([class]) {
  transition-property: outline;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

:focus-visible:where(:not(:hover, :active)) {
  outline-color: #9595a1;
}

html {
  height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  display: flex;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  flex: 1 0 auto;
  color: #525264;
  display: flex;
  max-width: 100%;
  min-height: 100vh;
  line-height: 1.4;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  background-color: #f4f4f4;
}
.page__main {
  gap: var(--spacer-160);
  flex: 1 0 auto;
  display: flex;
  padding-bottom: var(--spacer-160);
  flex-direction: column;
}
.page__main:nth-child(2) {
  margin-top: 60px;
}
@media (max-width: 1023.8px) {
  .page__main:nth-child(2) {
    margin-top: 40px;
  }
}
@media (max-width: 767.8px) {
  .page__main:nth-child(2) {
    margin-top: 20px;
  }
}
.page__main:nth-child(3) {
  margin-top: 38px;
}
.page__footer {
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

.primary {
  color: #696fff;
}

.uppercase {
  text-transform: uppercase;
}

.no-scrollbar {
  overflow: hidden;
}

.gradient-red {
  background: var(--gradient-red, linear-gradient(135deg, #ce7a3c 0%, #d64f67 99.69%));
}

.gradient-pink {
  background: var(--gradient-pink, linear-gradient(140deg, #d64f67 0%, #dc55ae 100%));
}

.gradient-blue {
  background: var(--gradient-blue, linear-gradient(140deg, #8b78ff 0%, #555bdc 100%));
}

.gradient-green {
  background: var(--gradient-green, linear-gradient(136deg, #22b678 0%, #839f10 100%));
}

.gradient-yellow {
  background: var(--gradient-yellow, linear-gradient(140deg, #d9bc23 0%, #ce793b 100%));
}

.gradient-pink-blue {
  background: var(--gradient-pink-blue, linear-gradient(318deg, #8b78ff 0%, #dc55ae 100%));
}

.gradient-blue-green {
  background: var(--gradient-blue-green, linear-gradient(316deg, #21bd56 0%, #555bdc 100%));
}

.gradient-green-yellow {
  background: var(--gradient-green-yellow, linear-gradient(137deg, #839f10 0%, #d9bc23 100%));
}

:where(p) {
  color: var(--text-color, currentColor);
}

:where(p + p,
p + ul,
p + ol) {
  margin-top: 0.5rem;
}

:where(a) {
  color: var(--link-color, var(--text-color, currentColor));
  text-underline-offset: 0.1875em;
  transition-duration: 0.2s;
  transition-property: color, outline, text-decoration;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  text-decoration-thickness: 1px;
}
@media (hover) {
  :where(a):hover {
    text-underline-offset: 0.125em;
    text-decoration-thickness: 2px;
  }
}

:where(a:not([class])) {
  border-radius: 4px;
}

:where(.ul, ul:not([class])) {
  padding-left: 1em;
  list-style-type: disc;
  list-style-position: inside;
}
:where(.ul, ul:not([class])) :where(ul, .ul,
li + li) {
  margin-top: 0.5rem;
}

:where(h1, .h1) {
  color: var(--h1-color, var(--headings-color, #393948));
  font-size: var(--h1-font-size);
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.01em;
  margin-top: var(--h1-margin-top, 1rem);
  margin-bottom: var(--h1-margin-bottom, var(--headings-margin-bottom, 32px));
}

:where(h2, .h2) {
  color: var(--h2-color, var(--headings-color, #393948));
  font-size: var(--h2-font-size);
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.01em;
  margin-top: var(--h2-margin-top, 1rem);
  margin-bottom: var(--h2-margin-bottom, var(--headings-margin-bottom, 32px));
}

:where(h3, .h3) {
  color: var(--h3-color, var(--headings-color, #393948));
  font-size: var(--h3-font-size);
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  margin-top: var(--h3-margin-top, 1rem);
  margin-bottom: var(--h3-margin-bottom, var(--headings-margin-bottom, 4px));
}

:where(h4, .h4) {
  color: var(--h4-color, var(--headings-color, #393948));
  font-size: var(--h4-font-size);
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: normal;
  margin-top: var(--h4-margin-top, 1rem);
  margin-bottom: var(--h4-margin-bottom, var(--headings-margin-bottom, 4px));
}

:where(h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4) {
  display: block;
}
:where(h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4) mark {
  color: #696fff;
  background: none;
}
:where(h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4):last-child {
  margin-bottom: 0;
}
:where(h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4):first-child {
  margin-top: 0;
}

:where(picture img) {
  aspect-ratio: inherit;
  border-radius: inherit;
}

.table {
  overflow: auto;
  max-width: 100%;
  font-size: 14px;
  margin-block: 0.5rem;
}
.table :where(th, td) {
  padding: 0.5rem;
  text-align: left;
  border-top: 1px solid #c2c2c9;
  border-left: 1px solid #c2c2c9;
  vertical-align: top;
}
.table :where(th, td):last-child {
  padding-right: 0;
}
.table :where(th, td):first-child {
  border-left: 0;
  padding-left: 0;
}
.table :where(tr:first-child) :where(th, td) {
  border-top: 0;
}
.table:last-child {
  margin-bottom: 0;
}
.table:first-child {
  margin-top: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.tingle-modal {
  --headings-color: currentColor;
  --headings-margin-bottom: 12px;
  color: #fff;
  inset: 0;
  opacity: 0;
  z-index: 2000;
  display: flex;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(4px);
  -webkit-overflow-scrolling: touch;
}
.tingle-modal--visible {
  opacity: 1;
  visibility: visible;
}
.tingle-modal--visible .tingle-modal-box {
  animation: scale 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}
.tingle-modal--overflow {
  overflow-y: scroll;
}

.tingle-enabled {
  left: 0;
  right: 0;
  position: fixed;
  overflow: hidden;
}

.tingle-modal-box {
  width: 100%;
  opacity: 1;
  display: flex;
  padding: 80px 104px 60px;
  position: relative;
  max-width: 856px;
  background: #696fff;
  flex-shrink: 0;
  will-change: opacity, transform;
  margin-block: auto;
  border-radius: 20px;
  flex-direction: column;
}
@media (max-width: 767.8px) {
  .tingle-modal-box {
    max-width: 856px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    border-radius: 0;
    padding-inline: 16px;
  }
}
@media (max-width: 767.8px) {
  .tingle-modal-box__content {
    margin-top: auto;
  }
}
.tingle-modal-box__footer {
  display: flex;
}
.tingle-modal-box .tingle-modal-close {
  top: 16px;
  right: 16px;
  z-index: 10;
  position: absolute;
  background: #787dff;
}
.tingle-modal-box .tingle-modal-finish {
  margin-top: 32px;
  margin-left: auto;
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.swiper {
  z-index: 1;
  padding: 0;
  position: relative;
  overflow: hidden;
  list-style: none;
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  box-sizing: content-box;
  will-change: transform;
  transition-property: transform;
  transition-timing-function: ease-out;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;
}
.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-autoheight,
.swiper-autoheight .swiper-slide {
  height: auto;
}
.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-pagination {
  gap: 8px;
  display: flex;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: flex;
  position: relative;
  background: #d9d9df;
  border-radius: 50%;
  transition-property: all;
}
.swiper-pagination-bullet::after {
  inset: -4px;
  content: "";
  position: absolute;
}

.swiper-pagination-bullet-active {
  background: #393948;
}

.wow {
  will-change: opacity, transform;
  transition-delay: calc(var(--index) * 0.025s) !important;
  transition-duration: 0.6s !important;
  transition-property: opacity, transform !important;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}
.wow_fade-up {
  opacity: 0 !important;
  transform: translateY(48px) !important;
}
.wow_scale-in {
  opacity: 0 !important;
  transform: scale(0.86) !important;
  transform-origin: 50% 0 !important;
}
.wow[data-scroll=in] {
  opacity: 1 !important;
  transform: none !important;
}

.btn {
  border: 1px solid transparent;
  display: inline-flex;
  position: relative;
  user-select: none;
  align-items: center;
  border-radius: 12px;
  text-decoration: none;
  justify-content: center;
  transition-property: color, border, outline, background;
}
.btn__spinner {
  inset: 0;
  z-index: 1;
  opacity: 0;
  position: absolute;
  background: inherit;
  border-radius: inherit;
  pointer-events: none;
  transition-property: opacity;
}
.btn__spinner::after {
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  border: 2px solid currentColor;
  content: "";
  position: absolute;
  animation: spin 1s linear infinite both;
  border-radius: 50%;
  border-right-color: transparent;
}
.btn:disabled {
  opacity: 0.36;
}
.btn_base {
  gap: 8px;
  font-weight: 600;
}
.btn_base-md {
  padding: 11px 19px;
  min-width: 100px;
  font-size: 16px;
  line-height: 1.5;
}
.btn_base-lg {
  padding: 21px 27px;
  font-size: 20px;
  min-width: 180px;
  line-height: 1.2;
}
.btn_icon {
  font-size: 24px;
  line-height: 1;
}
.btn_icon::after {
  transition-property: transform;
}
.btn_icon-md {
  padding: 11px;
  min-width: 48px;
  min-height: 48px;
}
.btn_icon-lg {
  padding: 21px;
  min-width: 68px;
  min-height: 68px;
}
.btn_dark {
  color: #fff;
  background: #525264;
}
.btn_dark:focus-visible:where(:not(:hover, :active)) {
  color: #fff;
  background: #525264;
}
@media (hover) {
  .btn_dark:hover {
    color: #fff;
    background: #6e6e7e;
  }
  .btn_dark:hover:active {
    color: #fff;
    background: #525264;
  }
}
.btn_dark:disabled {
  color: #fff;
  background: #525264;
}
.btn_default {
  color: #393948;
  background: #fff;
}
.btn_default:focus-visible:where(:not(:hover, :active)) {
  color: #393948;
  background: #fff;
}
@media (hover) {
  .btn_default:hover {
    color: #393948;
    background: #d9d9df;
  }
  .btn_default:hover:active {
    color: #393948;
    background: #cbcdff;
  }
}
.btn_default:disabled {
  color: #393948;
  background: #fff;
}
.btn_primary {
  color: #fff;
  background: #696fff;
}
.btn_primary:focus-visible:where(:not(:hover, :active)) {
  color: #fff;
  background: #696fff;
}
@media (hover) {
  .btn_primary:hover {
    color: #fff;
    background: #787dff;
  }
  .btn_primary:hover:active {
    color: #fff;
    background: #555bdc;
  }
}
.btn_primary:disabled {
  color: #fff;
  background: #696fff;
}
.btn_round {
  border-radius: 1000px;
}
.btn_circle {
  border-radius: 50%;
}
.btn_block {
  display: flex;
}
.btn_center {
  margin-inline: auto;
}
.btn_loading {
  pointer-events: none;
}
.btn_loading .btn__spinner {
  opacity: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.icon {
  display: inline-flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;
}
.icon::after {
  width: 1em;
  height: 1em;
  content: "";
  display: flex;
  background-color: currentColor;
  transition-property: transform;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
.icon_link::after {
  mask-image: url("/src/uploads/next/assets/icons/link.svg");
}
.icon_vimeo::after {
  mask-image: url("/src/uploads/next/assets/icons/vimeo.svg");
}
.icon_twitter::after {
  mask-image: url("/src/uploads/next/assets/icons/twitter.svg");
}
.icon_facebook::after {
  mask-image: url("/src/uploads/next/assets/icons/facebook.svg");
}
.icon_linkedin::after {
  mask-image: url("/src/uploads/next/assets/icons/linkedin.svg");
}
.icon_instagram::after {
  mask-image: url("/src/uploads/next/assets/icons/instagram.svg");
}
.icon_play::after {
  mask-image: url("/src/uploads/next/assets/icons/play.svg");
}
.icon_plus::after {
  mask-image: url("/src/uploads/next/assets/icons/plus.svg");
}
.icon_minus::after {
  mask-image: url("/src/uploads/next/assets/icons/minus.svg");
}
.icon_close::after {
  mask-image: url("/src/uploads/next/assets/icons/close.svg");
}
.icon_arrow-left::after {
  transform: rotate(180deg);
  mask-image: url("/src/uploads/next/assets/icons/arrow-right.svg");
}
.icon_arrow-right::after {
  mask-image: url("/src/uploads/next/assets/icons/arrow-right.svg");
}
.icon_chevron-down::after {
  mask-image: url("/src/uploads/next/assets/icons/chevron-down.svg");
}
.icon_arrow-up-right::after {
  mask-image: url("/src/uploads/next/assets/icons/arrow-up-right.svg");
}

.tabs {
  gap: var(--spacer-32);
  display: flex;
  flex-direction: column;
}
.tabs__menu {
  border: 1px solid #9595a1;
  display: flex;
  padding: 4px;
  margin-right: auto;
  border-radius: 100px;
}
@media (max-width: 767.8px) {
  .tabs__menu {
    margin-right: 0;
    border-radius: 12px;
    flex-direction: column;
  }
}
.tabs__link {
  color: #9595a1;
  border: none;
  outline: none;
  padding: 12px 16px;
  display: inline-flex;
  user-select: none;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  transition-property: color, background;
}
@media (max-width: 767.8px) {
  .tabs__link {
    border-radius: 12px;
  }
}
@media (hover) {
  .tabs__link:hover {
    color: #525264;
  }
}
.tabs__link_active {
  color: #fff;
  background: #525264;
  pointer-events: none;
}
.tabs__item {
  display: none;
  flex-direction: column;
}
.tabs__item_active {
  display: flex;
}
.tabs__item-body {
  gap: var(--spacer-32);
  display: flex;
  margin-top: var(--spacer-32);
  flex-direction: column;
}

.stack {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.stack_md {
  gap: 32px;
}

.hstack {
  gap: 20px;
  display: flex;
  align-items: center;
}
.hstack_md {
  gap: 32px;
}

.picture {
  width: 100%;
  border-radius: 20px;
}

.columns {
  column-gap: 24px;
}
.columns_2col {
  column-count: 2;
}
@media (max-width: 1023.8px) {
  .columns_2col {
    column-count: 1;
  }
}

.section {
  gap: var(--spacer-32);
  display: flex;
  flex-direction: column;
}
.section__head {
  --h2-margin-top: 60px;
}
.section__head :first-child {
  margin-top: 0;
}
.section__body {
  gap: var(--spacer-80);
  display: flex;
  flex-direction: column;
}
.section__foot {
  display: flex;
  justify-content: center;
}
.section_solid {
  --headings-color: $gray-10;
  color: #fff;
  padding: 100px 102px;
  background: #696fff;
  border-radius: 20px;
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .section_solid {
    padding: 60px;
  }
}
@media (max-width: 767.8px) {
  .section_solid {
    width: 100%;
    padding: 40px 16px;
    max-width: none;
    border-radius: 0;
  }
}
.section_solid-gray {
  background: #393948;
}

.container {
  width: calc(100% - var(--container-gutter, 0px));
  max-width: calc(var(--container-width, 1920px) - var(--container-gutter, 0px));
  margin-inline: auto;
}
.container_lg {
  --container-width: 1440px;
}
@media (min-width: 1440px) {
  .container_lg {
    --container-gutter: 216px;
  }
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .container_lg {
    --container-gutter: 96px;
  }
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .container_lg {
    --container-gutter: 64px;
  }
}
@media (max-width: 767.8px) {
  .container_lg {
    --container-gutter: 32px;
  }
}
.container_md {
  --container-width: 1024px;
}
@media (min-width: 1440px) {
  .container_md {
    --container-gutter: 216px;
  }
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .container_md {
    --container-gutter: 96px;
  }
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .container_md {
    --container-gutter: 64px;
  }
}
@media (max-width: 767.8px) {
  .container_md {
    --container-gutter: 32px;
  }
}

.not-found {
  gap: 24px;
  display: flex;
}
@media (min-width: 1024px) {
  .not-found {
    align-items: center;
  }
}
@media (max-width: 1023.8px) {
  .not-found {
    gap: 60px;
    flex-direction: column;
  }
}
.not-found__cell {
  flex: 1;
}
.not-found__cell:last-child {
  display: flex;
}
@media (max-width: 1023.8px) {
  .not-found__cell:last-child {
    justify-content: center;
  }
}
.not-found__buttons {
  gap: 40px;
  display: flex;
  margin-top: 32px;
  align-items: center;
}
@media (max-width: 767.8px) {
  .not-found__buttons {
    gap: 24px;
    margin-inline: auto;
    flex-direction: column;
  }
}

.media-block {
  display: flex;
  align-items: center;
}
.media-block__main, .media-block__side {
  flex: 1 0 50%;
}
@media (max-width: 1023.8px) {
  .media-block__main, .media-block__side {
    flex: auto;
  }
}
.media-block__main {
  padding: 80px;
  background: #fff;
  min-height: 60%;
  align-items: center;
  border-radius: 20px;
}
@media (min-width: 1440px) and (max-width: 1919.8px) {
  .media-block__main {
    padding: 60px;
  }
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .media-block__main {
    padding: 48px;
  }
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .media-block__main {
    padding: 40px;
    min-height: auto;
  }
}
@media (max-width: 767.8px) {
  .media-block__main {
    padding: 20px;
    min-height: auto;
  }
}
.media-block__side {
  width: auto;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  aspect-ratio: 600/374;
  border-radius: 20px;
  margin-block: calc(25% * 0.0871557427);
}
@media (max-width: 1023.8px) {
  .media-block__side {
    width: 96%;
    margin-inline: auto;
  }
}
@media (max-width: 767.8px) {
  .media-block__side {
    margin-block: calc(50% * 0.0871557427);
  }
}
.media-block__object {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  border-radius: inherit;
}
@media (max-width: 1023.8px) {
  .media-block_ltr {
    flex-direction: column;
  }
}
.media-block_ltr .media-block__main {
  padding-right: 120px;
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .media-block_ltr .media-block__main {
    padding-right: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .media-block_ltr .media-block__main {
    padding-right: 40px;
  }
}
@media (max-width: 767.8px) {
  .media-block_ltr .media-block__main {
    padding-right: 20px;
  }
}
.media-block_ltr .media-block__side {
  transform: rotate(-5deg);
  margin-left: -60px;
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .media-block_ltr .media-block__side {
    margin-left: -40px;
  }
}
@media (max-width: 1023.8px) {
  .media-block_ltr .media-block__side {
    margin-top: 0;
    margin-left: auto;
  }
}
.media-block_rtl {
  flex-direction: row-reverse;
}
@media (max-width: 1023.8px) {
  .media-block_rtl {
    flex-direction: column-reverse;
  }
}
.media-block_rtl .media-block__main {
  padding-left: 120px;
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .media-block_rtl .media-block__main {
    padding-left: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .media-block_rtl .media-block__main {
    padding-left: 40px;
  }
}
@media (max-width: 767.8px) {
  .media-block_rtl .media-block__main {
    padding-left: 20px;
  }
}
.media-block_rtl .media-block__side {
  transform: rotate(5deg);
  margin-right: -60px;
}
@media (min-width: 1024px) and (max-width: 1439.8px) {
  .media-block_rtl .media-block__side {
    margin-right: -40px;
  }
}
@media (max-width: 1023.8px) {
  .media-block_rtl .media-block__side {
    margin-right: auto;
    margin-bottom: 0;
  }
}

.media-section {
  gap: 48px;
  display: flex;
  flex-direction: column;
}
.media-section__body {
  gap: 48px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023.8px) {
  .media-section__body {
    gap: 60px;
  }
}

.logo {
  color: #393948;
  width: auto;
  height: 1em;
  display: inline-flex;
  font-size: 28px;
  line-height: 1;
  aspect-ratio: 191/28;
  text-decoration: none;
  background-color: currentcolor;
  transition-property: color;
  mask-size: auto 100%;
  mask-image: url("/src/uploads/next/assets/logo-full.svg");
  mask-repeat: no-repeat;
  mask-position: center;
}
.logo:hover {
  color: #696fff;
}

.lift {
  inset: 0;
  z-index: 10;
  display: flex;
  padding: 0 10px 24px;
  position: fixed;
  overflow: hidden;
  pointer-events: none;
}
.lift__inner {
  display: flex;
  margin-top: auto;
}
.lift__btn {
  width: 60px;
  height: 60px;
  opacity: 0;
  visibility: hidden;
  margin-left: auto;
  border-color: #fff;
  transform-origin: 0 50%;
  transition-property: all;
}
@media (min-width: 1440px) {
  .lift__btn {
    transform: translate(100%, -500%) scale(0.6);
    margin-right: -24px;
  }
}
@media (max-width: 1439.8px) {
  .lift__btn {
    transform: translateY(-500%) scale(0.6);
  }
}
.lift__btn::after {
  transform: rotate(180deg);
}
.lift__btn_show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
@media (min-width: 1440px) {
  .lift__btn_show {
    transform: translateX(100%);
  }
}
@media (max-width: 1439.8px) {
  .lift__btn_show {
    transform: none;
  }
}

.header {
  top: 0;
  z-index: 100;
  display: flex;
  position: sticky;
  background: rgba(244, 244, 244, 0.5);
  margin-top: 12px;
  user-select: none;
  flex-direction: column;
  backdrop-filter: blur(8px);
}
.header__main {
  display: flex;
  align-items: center;
  margin-block: 20px;
  justify-content: space-between;
}
.header__logo {
  z-index: 15;
  position: relative;
}
.header__switch {
  gap: 6px;
  z-index: 15;
  display: none;
  position: relative;
  flex-direction: column;
}
@media (max-width: 1023.8px) {
  .header__switch {
    display: flex;
  }
}
.header__switch-line {
  width: 20px;
  height: 2px;
  position: relative;
  background: currentColor;
  border-radius: 2px;
  transition-property: all;
}
.header_menu-open {
  z-index: 1500;
}
.header_menu-open .header__switch-line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.header_menu-open .header__switch-line:nth-child(2) {
  opacity: 0;
  transform: scaleX(0.6);
  transition-delay: -0.15s;
}
.header_menu-open .header__switch-line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.navbar {
  color: #393948;
}
@media (max-width: 1023.8px) {
  .navbar {
    top: 0;
    left: 0;
    color: #393948;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    opacity: 0;
    z-index: 10;
    position: fixed;
    visibility: hidden;
    background: #f4f4f4;
    transition-property: opacity, visibility;
  }
}
.navbar__list {
  gap: 24px;
  display: flex;
}
@media (max-width: 1023.8px) {
  .navbar__list {
    gap: 0;
    inset: 100px 0 0;
    overflow: auto;
    position: absolute;
    flex-direction: column;
  }
}
.navbar__item {
  position: relative;
  perspective: 1px;
  backface-visibility: hidden;
}
@media (hover) and (min-width: 1024px) {
  .navbar__item:hover > .navbar__link {
    color: #6e6e7e;
  }
  .navbar__item:hover > .navbar__link::after {
    transform: rotate(-180deg);
  }
  .navbar__item:hover > .navbar__dropdown {
    opacity: 1;
    transform: translate(-50%, 0);
    visibility: visible;
  }
}
@media (max-width: 1023.8px) {
  .navbar__item_open .navbar__link::after {
    transform: rotate(-180deg);
  }
}
.navbar__item_open > .navbar__dropdown {
  display: flex;
}
.navbar__link {
  gap: 4px;
  display: flex;
  padding: 4px 0;
  font-weight: 600;
  line-height: 1.5;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition-property: color, outline, background;
}
@media (max-width: 1023.8px) {
  .navbar__link {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    padding-block: 8px;
    padding-inline: 32px;
  }
}
@media (max-width: 767.8px) {
  .navbar__link {
    padding-inline: 16px;
  }
}
.navbar__link:focus-visible:where(:not(:hover, :active)) {
  border-radius: 4px;
}
.navbar__link:not(:last-child)::after {
  width: 1em;
  height: 1em;
  display: inline-flex;
  font-size: 24px;
  background-color: currentColor;
  mask-size: contain;
  mask-image: url("/src/uploads/next/assets/icons/chevron-down.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  content: "";
  font-size: 18px;
  transition-property: all;
}
@media (min-width: 1024px) {
  .navbar__link_active {
    border-color: currentColor;
  }
}
@media (max-width: 1023.8px) {
  .navbar__link_active {
    color: #696fff;
  }
}
.navbar__sublist {
  flex-grow: 1;
  border-radius: inherit;
}
@media (min-width: 1024px) {
  .navbar__sublist_grid {
    display: grid;
    border-radius: 0;
    grid-template-columns: repeat(2, 1fr);
  }
}
.navbar__subitem {
  display: flex;
  position: relative;
  perspective: 1px;
  flex-direction: column;
  backface-visibility: hidden;
}
@media (hover) and (min-width: 1024px) {
  .navbar__subitem:hover > .navbar__dropdown {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
.navbar__subitem:last-child {
  border-bottom: none;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.navbar__subitem:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
@media (min-width: 1024px) {
  .navbar__subitem_grid {
    border-color: rgba(37, 37, 50, 0.4);
    border-width: 0 1px 1px 0;
  }
  .navbar__subitem_grid:last-child {
    margin-top: -1px;
    grid-column: 1/-1;
    border-right: none;
    border-top-width: 1px;
  }
  .navbar__subitem_grid:nth-child(2n) {
    border-right: none;
  }
}
.navbar__sublink {
  gap: 4px;
  display: flex;
  padding: 14px 20px;
  position: relative;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  border-radius: inherit;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  transition-property: color, background;
}
@media (max-width: 1023.8px) {
  .navbar__sublink {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    padding-block: 6px;
    padding-inline: 32px;
  }
}
@media (max-width: 767.8px) {
  .navbar__sublink {
    padding-inline: 16px;
  }
}
@media (hover) and (min-width: 1024px) {
  .navbar__sublink:hover {
    color: #fff;
    background: #252532;
  }
}
.navbar__sublink_l2 {
  padding: 14px 20px;
}
.navbar__sublink_l3 {
  padding: 14px;
}
@media (min-width: 1024px) {
  .navbar__sublink_grid {
    padding: 12px;
    justify-content: flex-start;
  }
  :last-child > .navbar__sublink_grid {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .navbar__sublink_active {
    color: #fff;
    background: #252532;
  }
}
@media (max-width: 1023.8px) {
  .navbar__sublink_active {
    color: #696fff;
  }
}
@media (min-width: 1024px) {
  .navbar__sublink_hide-on-desktop {
    display: none;
  }
}
.navbar__sublink-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  white-space: normal;
}
@media (max-width: 1023.8px) {
  .navbar__sublink-text {
    display: none;
  }
}
.navbar__dropdown {
  --text-color: #f4f4f4;
  display: none;
  background: #393948;
  will-change: transform;
  flex-direction: column;
  transition-property: opacity, transform, visibility;
}
@media (min-width: 1024px) {
  .navbar__dropdown {
    opacity: 0;
    display: flex;
    position: absolute;
    visibility: hidden;
    border-radius: 8px;
  }
}
@media (max-width: 1023.8px) {
  .navbar__dropdown {
    --text-color: #393948;
    background: #f4f4f4;
  }
}
@media (min-width: 1024px) {
  .navbar__dropdown_l2 {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 12px);
    margin-top: -1px;
  }
}
@media (min-width: 1024px) {
  .navbar__dropdown_l3 {
    top: 0;
    left: 100%;
    margin-left: 2px;
    transform: translateY(12px);
  }
  .navbar__dropdown_l3::before {
    inset: -2px;
    content: "";
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .navbar__dropdown_grid {
    left: 50%;
    overflow: hidden;
    min-width: 448px;
  }
}
@media (max-width: 1023.8px) {
  .navbar_open {
    opacity: 1;
    visibility: visible;
  }
}

.crumbs {
  gap: 4px;
  color: #393948;
  display: flex;
  flex-wrap: wrap;
}
.crumbs__item:last-child {
  color: #9595a1;
}
.crumbs__link {
  font-weight: 500;
  text-decoration-color: transparent;
}
.crumbs__link:hover {
  text-decoration-color: currentColor;
}

.footer {
  border-top: 2px solid #d9d9df;
}
.footer__inner {
  gap: var(--spacer-60);
  display: flex;
  padding-block: var(--spacer-80);
  flex-direction: column;
}
.footer__logo {
  margin: 0 auto;
}
.footer__menu {
  color: #393948;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023.8px) {
  .footer__menu {
    gap: 40px 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.8px) {
  .footer__menu {
    grid-template-columns: 1fr;
  }
}
.footer__list {
  gap: 6px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .footer__list:nth-child(2n-1) {
    align-items: flex-end;
  }
}
.footer__item {
  margin-bottom: 0;
}
.footer__link {
  gap: 4px;
  display: inline-flex;
  line-height: 24px;
  align-items: center;
  text-decoration-color: transparent;
}
.footer__link:hover {
  color: #696fff;
}
.footer__link:hover .footer__link-name {
  text-decoration-color: currentColor;
}
.footer__link-name {
  text-decoration: inherit;
}
.footer__copy {
  color: #393948;
  margin: 0 auto;
  font-size: 12px;
  line-height: 1;
}
@media (max-width: 767.8px) {
  .footer__copy {
    margin: 0;
  }
}

.input {
  gap: 8px;
  display: flex;
  position: relative;
  transform: translateZ(0);
  flex-direction: column;
}
.input__field {
  order: 2;
  width: 100%;
  color: #f4f4f4;
  resize: none;
  border: 1px solid #b0b4ff;
  outline: none;
  padding: 20px 16px;
  background: #555bdc;
  border-radius: 12px;
  transition-property: color, border, background, box-shadow;
}
.input__field::placeholder {
  color: #b0b4ff;
  user-select: none;
  pointer-events: none;
  transition-property: color;
}
.input__field:focus::placeholder {
  color: transparent;
}
.input__field:focus, .input__field:hover {
  box-shadow: 0 0 0 1px #f2f3ff;
  border-color: #f2f3ff;
}
.input__field:disabled {
  opacity: 0.36;
}
.input__field_textarea {
  height: 152px;
}
.input__field.invalid {
  box-shadow: 0 0 0 1px #d5444d;
  border-color: #d5444d;
}
.input__label {
  order: 1;
  color: #fff;
  cursor: pointer;
  transition-property: color;
}
.input__error {
  order: 3;
  color: #d5444d;
  line-height: 1.2;
}
.input__error:empty {
  display: none;
}

.logos {
  display: flex;
  position: relative;
  user-select: none;
  justify-content: center;
}
.logos__show {
  margin: 0;
  max-width: 880px;
}
.logos__slide {
  display: flex;
}
.logos__item {
  margin: 0 auto;
  opacity: 0.5;
  display: flex;
  outline: none !important;
  align-items: center;
  border-radius: 8px;
  transition-property: opacity, outline;
}
@media (hover) {
  .logos__item:hover {
    opacity: 1;
  }
}
.logos__arrow {
  color: #393948;
  opacity: 0.5;
  outline: none;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  transition-property: opacity;
}
@media (max-width: 767.8px) {
  .logos__arrow {
    width: 48px;
  }
}
.logos__arrow:hover {
  opacity: 1;
}
.logos__arrow_prev {
  padding-right: 48px;
  justify-content: flex-end;
}
@media (max-width: 767.8px) {
  .logos__arrow_prev {
    padding-right: 24px;
  }
}
@media (hover) {
  .logos__arrow_prev:hover::after {
    transform: translateX(-4px) rotate(180deg);
  }
}
.logos__arrow_next {
  padding-left: 48px;
}
@media (max-width: 767.8px) {
  .logos__arrow_next {
    padding-left: 24px;
  }
}
@media (hover) {
  .logos__arrow_next:hover::after {
    transform: translateX(4px);
  }
}

.posts-menu {
  gap: 8px;
  display: flex;
  align-items: flex-start;
}
.posts-menu__label {
  color: #393948;
  height: 28px;
  display: flex;
  align-items: center;
}
.posts-menu__body {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.posts-menu__link {
  color: #393948;
  display: flex;
  padding: 8px 12px;
  font-size: 12px;
  background: #fff;
  line-height: 1;
  align-items: center;
  border-radius: 6px;
  outline-offset: 2px;
  text-decoration: none;
  transition-property: color, outline, background;
}
.posts-menu__link_active {
  color: #fff;
  background: #393948;
}

.post {
  color: #393948;
  display: flex;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  text-decoration: none;
  transition-property: color, outline, background;
}
.post__thumb {
  overflow: hidden;
  position: relative;
  perspective: 1000px;
  aspect-ratio: 392/206;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.post__thumb-src {
  width: 100%;
  object-fit: cover;
  transition: all 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform;
}
.post__body {
  gap: 12px;
  display: flex;
  padding: 16px 16px 24px 16px;
  flex-grow: 1;
  flex-direction: column;
}
.post__date {
  font-size: 12px;
  margin-top: auto;
  font-weight: 400;
  line-height: 1;
}
.post__title {
  margin-bottom: 0;
}
.post__about {
  color: #6e6e7e;
}
.post__author {
  gap: 8px;
  display: flex;
  align-items: center;
}
.post__author-img {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background-size: cover;
}
.post__author-name {
  gap: 8px;
  display: flex;
  font-weight: 700;
  align-items: baseline;
}
.post__author-role {
  color: #6e6e7e;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}
@media (hover) {
  .post:hover {
    background: #d9d9df;
  }
  .post:hover:active {
    color: #393948;
    background: #cbcdff;
  }
  .post:hover .post__thumb-src {
    transform: scale(1.06) rotate(-2deg);
  }
}
.post_expert .post__thumb {
  aspect-ratio: auto;
}

.posts {
  gap: var(--spacer-24);
  display: flex;
  flex-wrap: wrap;
}
.posts__item {
  width: calc(33.3333333333% - 16px);
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .posts__item {
    width: calc(50% - 12px);
  }
}
@media (max-width: 767.8px) {
  .posts__item {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .posts_blog .posts__item:nth-child(1), .posts_blog .posts__item:nth-child(4n+1) {
    width: 100%;
  }
}
.posts_recently .posts__item_more {
  display: none;
}
@media (min-width: 1024px) {
  .posts_solutions .posts__item:nth-child(4n) {
    width: 100%;
  }
}
.posts_center {
  justify-content: center;
}

.links {
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 1023.8px) {
  .links {
    grid-template-columns: 1fr;
  }
}
.links__item {
  gap: 80px;
  color: #fff;
  display: flex;
  padding: 24px;
  box-shadow: 0 0 0 #696fff;
  border-radius: 20px;
  flex-direction: column;
  text-decoration: none;
  background-color: #555bdc;
  transition-property: outline, box-shadow;
}
@media (max-width: 1023.8px) {
  .links__item {
    gap: 32px;
  }
}
.links__item:nth-child(1) {
  background-image: linear-gradient(0deg, rgba(57, 57, 72, 0.8) 0%, rgba(57, 57, 72, 0.8) 100%);
}
.links__item:nth-child(2) {
  background-image: linear-gradient(0deg, rgba(57, 57, 72, 0.4) 0%, rgba(57, 57, 72, 0.4) 100%);
}
@media (hover) {
  .links__item:hover {
    background: #696fff !important;
    box-shadow: 0 0 0 4px #696fff;
  }
  .links__item:hover .links__icon {
    transform: translate(5px, -5px);
  }
}
.links__icon {
  margin-left: auto;
  transition-property: transform;
}
.links__name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.feature {
  gap: 24px;
  display: flex;
  padding: 24px;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
}
.feature__ico {
  border-radius: 16px;
}
.feature__img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 344/192;
  border-radius: 16px;
}
.feature_primary {
  background: #555bdc;
}

.features {
  gap: var(--spacer-24);
  display: grid;
}
.features_2col {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767.8px) {
  .features_2col {
    grid-template-columns: 1fr;
  }
}
.features_3col {
  grid-template-columns: repeat(3, 1fr);
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .features_3col {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.8px) {
  .features_3col {
    grid-template-columns: 1fr;
  }
}

.benefits {
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1023.8px) {
  .benefits {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.8px) {
  .benefits {
    grid-template-columns: 1fr;
  }
}
.benefits__item {
  --headings-color: currentColor;
  flex: 1;
  color: #fff;
  padding: 32px;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
}
.benefits__item:nth-child(1) {
  background: linear-gradient(135deg, #CE7A3C 0%, #D64F67 99.69%);
}
.benefits__item:nth-child(2) {
  background: linear-gradient(140deg, #D64F67 0%, #DC55AE 100%);
}
.benefits__item:nth-child(3) {
  background: linear-gradient(318deg, #8B78FF 0%, #DC55AE 100%);
}
.benefits__item:nth-child(4) {
  background: linear-gradient(140deg, #8B78FF 0%, #555BDC 100%);
}
.benefits_text-center {
  text-align: center;
}
.benefits_text-middle .benefits__item {
  justify-content: center;
}

.feedback {
  --text-color: #fff;
  --headings-color: #fff;
  gap: 32px;
  display: flex;
  padding: 80px;
  background: #696fff;
  border-radius: 20px;
  flex-direction: column;
}
@media (min-width: 768px) and (max-width: 1023.8px) {
  .feedback {
    padding: 60px;
  }
}
@media (max-width: 767.8px) {
  .feedback {
    width: 100%;
    padding: 40px 16px;
    max-width: none;
    border-radius: 0;
  }
}
.feedback_row {
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 1023.8px) {
  .feedback_row {
    grid-template-columns: 1fr;
  }
}
.feedback_body {
  gap: 24px;
  display: flex;
  flex-direction: column;
}
.feedback_foot {
  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1023.8px) {
  .feedback_foot {
    gap: 40px;
    flex-direction: column-reverse;
  }
}
.feedback_policy {
  font-size: 12px;
}

.contactus {
  --text-color: #fff;
  --headings-color: #fff;
  gap: 32px;
  display: flex;
  flex-direction: column;
}
.contactus__head {
  --h2-margin-bottom: 12px;
}
.contactus__body {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.contactus__foot {
  gap: 24px;
  display: flex;
  margin-top: 4px;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1023.8px) {
  .contactus__foot {
    gap: 40px;
    flex-direction: column-reverse;
  }
}
.contactus_policy {
  font-size: 12px;
}

.expert-header {
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media (max-width: 1023.8px) {
  .expert-header {
    gap: 0;
    grid-template-columns: 1fr;
  }
}
.expert-header__view {
  display: flex;
  position: relative;
  background: #d9d9df;
  border-radius: 20px;
}
@media (max-width: 1023.8px) {
  .expert-header__view {
    aspect-ratio: 684/490;
    border-radius: 20px 20px 0 0;
  }
}
.expert-header__thumb {
  flex-grow: 1;
  border-radius: inherit;
}
.expert-header__thumb-src {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 10%;
}
.expert-header__link {
  color: #fff;
  right: 20px;
  bottom: 20px;
  display: flex;
  padding: 12px;
  position: absolute;
  background: #696fff;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  justify-content: center;
}
.expert-header__body {
  --headings-color: $gray-10;
  color: #fff;
  display: flex;
  padding: 48px;
  background: #696fff;
  border-radius: 20px;
  flex-direction: column;
}
@media (max-width: 767.8px) {
  .expert-header__body {
    padding: 24px;
    border-radius: 0 0 20px 20px;
  }
}
.expert-header__role {
  font-size: 12px;
  line-height: 1;
}
.expert-header__name {
  margin-block: 8px 32px;
}