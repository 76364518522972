@use 'src/styles/core' as *;

$navbar-link-color: $gray-80 !default;
$navbar-link-hover-color: $gray-60 !default;
$navbar-link-active-color: $blue-70 !default;

$navbar-dropdown-bg: $gray-80 !default;
$navbar-dropdown-color: $gray-20 !default;
$navbar-dropdown-border: rgba($gray-90, 0.4) !default;

$navbar-dropdown-hover-bg: $gray-90 !default;
$navbar-dropdown-hover-color: $gray-10 !default;

$navbar-dropdown-active-bg: $gray-90 !default;
$navbar-dropdown-active-color: $gray-10 !default;

.navbar {
  $b: &;

  color: $navbar-link-color;

  @include media-breakpoint-down(md) {
    top: 0;
    left: 0;
    color: $gray-80;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    opacity: 0;
    z-index: 10;
    position: fixed;
    visibility: hidden;
    background: $gray-20;
    transition-property: opacity, visibility;
  }

  &__list {
    gap: 24px;
    display: flex;

    @include media-breakpoint-down(md) {
      gap: 0;
      inset: 100px 0 0;
      overflow: auto;
      position: absolute;
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    perspective: 1px;
    backface-visibility: hidden;

    @include media-hover {
      @include media-breakpoint-up(md) {
        > #{$b}__link {
          color: $navbar-link-hover-color;

          &::after {
            transform: rotate(-180deg);
          }
        }

        > #{$b}__dropdown {
          opacity: 1;
          transform: translate(-50%, 0);
          visibility: visible;
        }
      }
    }

    &_open {
      #{$b}__link {
        &::after {
          @include media-breakpoint-down(md) {
            transform: rotate(-180deg);
          }
        }
      }

      > #{$b}__dropdown {
        display: flex;
      }
    }
  }

  &__link {
    gap: 4px;
    display: flex;
    padding: 4px 0;
    font-weight: 600;
    line-height: 1.5;
    align-items: center;
    white-space: nowrap;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition-property: color, outline, background;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.3;
      padding-block: 8px;
      padding-inline: map-get($gutter, md);
    }
    @include media-breakpoint-down(sm) {
      padding-inline: map-get($gutter, sm);
    }

    @include focus-visible {
      border-radius: $border-radius-xs;
    }

    &:not(:last-child) {
      &::after {
        @include make-icon(chevron-down);

        content: '';
        font-size: 18px;
        transition-property: all;
      }
    }

    &_active {
      @include media-breakpoint-up(md) {
        border-color: currentColor;
      }
      @include media-breakpoint-down(md) {
        color: $navbar-link-active-color;
      }
    }
  }

  &__sublist {
    flex-grow: 1;
    border-radius: inherit;

    &_grid {
      @include media-breakpoint-up(md) {
        display: grid;
        border-radius: 0;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__subitem {
    display: flex;
    position: relative;
    perspective: 1px;
    flex-direction: column;
    backface-visibility: hidden;

    @include media-hover {
      @include media-breakpoint-up(md) {
        > #{$b}__dropdown {
          opacity: 1;
          transform: none;
          visibility: visible;
        }
      }
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &_grid {
      @include media-breakpoint-up(md) {
        border-color: $navbar-dropdown-border;
        border-width: 0 1px 1px 0;

        &:last-child {
          margin-top: -1px;
          grid-column: 1 / -1;
          border-right: none;
          border-top-width: 1px;
        }

        &:nth-child(2n) {
          border-right: none;
        }
      }
    }
  }

  &__sublink {
    gap: 4px;
    display: flex;
    padding: 14px 20px;
    position: relative;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    white-space: nowrap;
    border-radius: inherit;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    transition-property: color, background;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      padding-block: 6px;
      padding-inline: map-get($gutter, md);
    }
    @include media-breakpoint-down(sm) {
      padding-inline: map-get($gutter, sm);
    }

    @include media-hover {
      @include media-breakpoint-up(md) {
        color: $navbar-dropdown-hover-color;
        background: $navbar-dropdown-hover-bg;
      }
    }

    &_l2 {
      padding: 14px 20px;
    }

    &_l3 {
      padding: 14px;
    }

    &_grid {
      @include media-breakpoint-up(md) {
        padding: 12px;
        justify-content: flex-start;

        :last-child > & {
          align-items: center;
        }
      }
    }

    &_active {
      @include media-breakpoint-up(md) {
        color: $navbar-dropdown-active-color;
        background: $navbar-dropdown-active-bg;
      }
      @include media-breakpoint-down(md) {
        color: $navbar-link-active-color;
      }
    }

    &_hide-on-desktop {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__sublink-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    white-space: normal;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__dropdown {
    --text-color: #{$navbar-dropdown-color};

    display: none;
    background: $navbar-dropdown-bg;
    will-change: transform;
    flex-direction: column;
    transition-property: opacity, transform, visibility;

    @include media-breakpoint-up(md) {
      opacity: 0;
      display: flex;
      position: absolute;
      visibility: hidden;
      border-radius: $border-radius-sm;
    }
    @include media-breakpoint-down(md) {
      --text-color: #{$gray-80};

      background: $gray-20;
    }

    &_l2 {
      @include media-breakpoint-up(md) {
        top: 100%;
        left: 50%;
        transform: translate(-50%, 12px);
        margin-top: -1px;
      }
    }

    &_l3 {
      @include media-breakpoint-up(md) {
        top: 0;
        left: 100%;
        margin-left: 2px;
        transform: translateY(12px);

        &::before {
          inset: -2px;
          content: '';
          position: absolute;
        }
      }
    }

    &_grid {
      @include media-breakpoint-up(md) {
        left: 50%;
        overflow: hidden;
        min-width: 448px;
      }
    }
  }

  &_open {
    @include media-breakpoint-down(md) {
      opacity: 1;
      visibility: visible;
    }
  }
}
