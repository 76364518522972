@use 'sass:math' as *;

@use 'src/styles/core' as *;

.container {
  width: calc(100% - var(--container-gutter, 0px));
  max-width: calc(var(--container-width, 1920px) - var(--container-gutter, 0px));
  margin-inline: auto;

  &_lg {
    --container-width: 1440px;

    @include media-breakpoint-up(lg) {
      --container-gutter: #{map-get($gutter, init) * 2};
    }
    @include media-breakpoint-between(md, lg) {
      --container-gutter: #{map-get($gutter, lg) * 2};
    }
    @include media-breakpoint-between(sm, md) {
      --container-gutter: #{map-get($gutter, md) * 2};
    }
    @include media-breakpoint-down(sm) {
      --container-gutter: #{map-get($gutter, sm) * 2};
    }
  }

  &_md {
    --container-width: 1024px;

    @include media-breakpoint-up(lg) {
      --container-gutter: #{map-get($gutter, init) * 2};
    }
    @include media-breakpoint-between(md, lg) {
      --container-gutter: #{map-get($gutter, lg) * 2};
    }
    @include media-breakpoint-between(sm, md) {
      --container-gutter: #{map-get($gutter, md) * 2};
    }
    @include media-breakpoint-down(sm) {
      --container-gutter: #{map-get($gutter, sm) * 2};
    }
  }
}
