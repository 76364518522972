@use 'src/styles/core' as *;

.post {
  $b: &;

  color: $gray-80;
  display: flex;
  background: $gray-10;
  border-radius: $border-radius-lg;
  flex-direction: column;
  text-decoration: none;
  transition-property: color, outline, background;

  &__thumb {
    overflow: hidden;
    position: relative;
    perspective: 1000px;
    aspect-ratio: 392 / 206;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &__thumb-src {
    width: 100%;
    object-fit: cover;
    transition: all 1.6s $ease;
    will-change: transform;
  }

  &__body {
    gap: 12px;
    display: flex;
    padding: 16px 16px 24px 16px;
    flex-grow: 1;
    flex-direction: column;
  }

  &__date {
    font-size: 12px;
    margin-top: auto;
    font-weight: 400;
    line-height: 1;
  }

  &__title {
    margin-bottom: 0;
  }

  &__about {
    color: $gray-60;
  }

  &__author {
    gap: 8px;
    display: flex;
    align-items: center;
  }

  &__author-img {
    width: 36px;
    height: 36px;
    border-radius: $border-radius-sm;
    background-size: cover;
  }

  &__author-name {
    gap: 8px;
    display: flex;
    font-weight: 700;
    align-items: baseline;
  }

  &__author-role {
    color: $gray-60;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
  }

  @include media-hover {
    background: $gray-30;

    &:active {
      color: $gray-80;
      background: $blue-30;
    }

    #{$b}__thumb-src {
      transform: scale(1.06) rotate(-2deg);
    }
  }

  &_expert {
    #{$b}__thumb {
      aspect-ratio: auto;
    }
  }
}
