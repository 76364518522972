@use 'src/styles/core' as *;

.logo {
  $b: &;

  color: $gray-80;
  width: auto;
  height: 1em;
  display: inline-flex;
  font-size: 28px;
  line-height: 1;
  aspect-ratio: 191 / 28;
  text-decoration: none;
  background-color: currentcolor;
  transition-property: color;
  mask: {
    size: auto 100%;
    image: url('/src/uploads/next/assets/logo-full.svg');
    repeat: no-repeat;
    position: center;
  }

  &:hover {
    color: $primary-color;
  }
}
