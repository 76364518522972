@use 'src/styles/core' as *;

$crumbs-color: $gray-50 !default;
$crumbs-link-color: $gray-80 !default;

.crumbs {
  gap: 4px;
  color: $crumbs-link-color;
  display: flex;
  flex-wrap: wrap;

  &__item {
    &:last-child {
      color: $crumbs-color;
    }
  }

  &__link {
    font-weight: 500;
    text-decoration-color: transparent;

    &:hover {
      text-decoration-color: currentColor;
    }
  }
}
