@use 'src/styles/core' as *;

.contactus {
  --text-color: #{$gray-10};
  --headings-color: #{$gray-10};

  gap: 32px;
  display: flex;
  flex-direction: column;

  &__head {
    --h2-margin-bottom: 12px;
  }

  &__body {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  &__foot {
    gap: 24px;
    display: flex;
    margin-top: 4px;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      gap: 40px;
      flex-direction: column-reverse;
    }
  }

  &_policy {
    font-size: 12px;
  }
}
