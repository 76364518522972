$red-10: #ffedee;
$red-20: #fed3d6;
$red-30: #fdb9bd;
$red-40: #fc9fa4;
$red-50: #ff7890;
$red-60: #fb6a73;
$red-70: #fa505a;
$red-80: #d5444d;
$red-90: #af383f;
$red-100: #892c31;

$blue-10: #f2f3ff;
$blue-20: #e1e2ff;
$blue-30: #cbcdff;
$blue-40: #b0b4ff;
$blue-50: #8e93ff;
$blue-60: #787dff;
$blue-70: #696fff;
$blue-80: #555bdc;
$blue-90: #464ab9;
$blue-100: #323583;

$gray-10: #fff;
$gray-20: #f4f4f4;
$gray-30: #d9d9df;
$gray-40: #c2c2c9;
$gray-50: #9595a1;
$gray-60: #6e6e7e;
$gray-70: #525264;
$gray-80: #393948;
$gray-90: #252532;
$gray-100: #181820;

$black-80: #24232a;
$black-90: #1c1b21;

$error-color: $red-80;

$primary-color: $blue-70;
$primary-color-hover: $blue-60;
$primary-color-active: $blue-80;
