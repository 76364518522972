@use 'src/styles/core' as *;

.lift {
  inset: 0;
  z-index: 10;
  display: flex;
  padding: 0 10px 24px;
  position: fixed;
  overflow: hidden;
  pointer-events: none;

  &__inner {
    display: flex;
    margin-top: auto;
  }

  &__btn {
    width: 60px;
    height: 60px;
    opacity: 0;
    visibility: hidden;
    margin-left: auto;
    border-color: $gray-10;
    transform-origin: 0 50%;
    transition-property: all;

    @include media-breakpoint-up(lg) {
      transform: translate(100%, -500%) scale(0.6);
      margin-right: -24px;
    }
    @include media-breakpoint-down(lg) {
      transform: translateY(-500%) scale(0.6);
    }

    &::after {
      transform: rotate(180deg);
    }

    &_show {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;

      @include media-breakpoint-up(lg) {
        transform: translateX(100%);
      }
      @include media-breakpoint-down(lg) {
        transform: none;
      }
    }
  }
}
